<template>
  <div :class="$style.groups">
    <el-form
      :model="form"
      :rules="rules"
      ref="form"
      label-position="left"
      label-width="20rem"
    >
      <el-form-item label="Название" prop="name">
        <el-input
          @focus="!form.slug ? handleNameInput(form.name) : ''"
          v-model="form.name"
        ></el-input>
      </el-form-item>
      <el-form-item label="Имя в url" prop="slug">
        <el-input v-model="form.slug" clearable></el-input>
      </el-form-item>
      <el-form-item label="Приоритет" prop="orderField">
        <el-input-number v-model="form.orderField" :min="0"></el-input-number>
      </el-form-item>
      <el-form-item label="Отображать в блоке Специальные" prop="isSpecial">
        <el-checkbox v-model="form.isSpecial"></el-checkbox>
      </el-form-item>
      <el-form-item label="Атрибуты" prop="attributes">
        <el-transfer
          v-model="groupAttributes"
          :data="attributes"
          :titles="['Все', 'Группа']"
          :props="{
            key: 'id',
            label: 'name'
          }"
        >
        </el-transfer>
      </el-form-item>
    </el-form>

    <el-button type="primary" @click="submitForm('form')">
      Редактировать
    </el-button>
    <el-button type="primary" @click="SaveAndComeBack('form')">
      Редактировать и вернуться
    </el-button>
  </div>
</template>

<script>
import delivery from '@/delivery'
import regexp from '@/helpers/regexp.js'
import notifications from '@/mixins/notifications.js'

import { transliterate } from '@/helpers/slug'
import { ADDWINE_ADMIN_ROUTES } from '@/constants/routing'

export default {
  mixins: [notifications],
  ADDWINE_ADMIN_ROUTES,
  data() {
    return {
      attributes: [],
      groupAttributes: [],
      form: {
        name: '',
        slug: '',
        attributeIDs: [],
        attributes: [],
        orderField: 0
      },
      rules: {
        name: [
          {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'blur'
          }
        ],
        slug: [
          {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'blur'
          },
          {
            pattern: regexp.slug,
            message: 'Введен недопустимый символ',
            trigger: 'change'
          }
        ],
        orderField: [
          {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'blur'
          }
        ]
      },
      isInit: false
    }
  },
  created() {
    this.getGroup()
    this.getAttributeList()
  },
  watch: {
    async groupAttributes(next, prev) {
      prev = prev ?? []
      if (!this.isInit) {
        this.isInit = true
        return
      }
      let difference = next
        .filter((x) => !prev.includes(x))
        .concat(prev.filter((x) => !next.includes(x)))
      if (prev.length < next.length) {
        await this.addAttributes(difference)
      } else {
        await this.deleteAttributes(difference)
      }
    }
  },
  methods: {
    async getGroup() {
      const loading = this.$loading({
        lock: true
      })

      const { value, error } =
        await delivery.ProductsCore.GroupsActions.getById(this.$route.params.id)
      if (error) return
      this.form = value
      this.groupAttributes = this.form.attributes?.map((i) => i.id)
      loading.close()
    },
    async getAttributeList() {
      const loading = this.$loading({
        lock: true
      })

      const { value, error } =
        await delivery.ProductsCore.AttributesActions.getList({
          limit: 100
        })
      if (error) return
      this.attributes = value.data
      this.total = value.meta.count

      loading.close()
    },

    async addAttributes(attributes) {
      const loading = this.$loading({
        lock: true
      })

      const res = await Promise.all(
        attributes.map((item) =>
          delivery.ProductsCore.GroupAttributesActions.create({
            groupId: this.$route.params.id,
            attributeId: item
          })
        )
      )

      res.forEach((i) => {
        if (i.error) {
          alert('Произошла ошибка создания')
          return
        }
      })

      loading.close()
    },
    async deleteAttributes(attributes) {
      const loading = this.$loading({
        lock: true
      })

      const res = await Promise.all(
        attributes.map((item) =>
          delivery.ProductsCore.GroupAttributesActions.delete({
            groupId: this.$route.params.id,
            attributeId: item
          })
        )
      )

      res.forEach((i) => {
        if (i.error) {
          alert('Произошла ошибка удаления')
          return
        }
      })

      loading.close()
    },

    handleNameInput(value) {
      this.form.slug = transliterate(value)
    },

    async submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const loading = this.$loading({
            lock: true
          })

          const result = await delivery.ProductsCore.GroupsActions.update(
            this.$route.params.id,
            this.form
          )

          loading.close()

          if (result.error) return

          this.showNotification('Группа успешно создана', 'success')
        }
      })
    },
    async SaveAndComeBack(form){
      await this.submitForm(form)
      this.$router.go(-1)
    },
  }
}
</script>

<style lang="scss" module>
@import '@/assets/styles/colors.scss';
.groups {
  padding: 1rem;
  label {
    margin: 0;
  }
  .attributesSelector {
    display: flex;
    padding: 1rem 0;
  }
}
</style>

<style>
.el-transfer-panel .el-transfer-panel__header .el-checkbox {
  display: contents !important;
}
.el-transfer-panel__item .el-checkbox__input {
  display: block;
}
</style>
